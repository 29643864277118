var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pl-8 pr-8"},[_c('div',{staticClass:"mb-xl-margin-file-sign"},[_c('v-row',{staticClass:"mt-10"},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"6"}},[_c('h1',{staticClass:"reference__title"},[_vm._v("Detalle solicitud general")])]),_c('v-col',{staticClass:"text-left",attrs:{"cols":"6"}},[_c('h1',{staticClass:"reference__title"},[_vm._v("Feedback")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"reference__card px-8 mt-8",attrs:{"outlined":"","elevation":"0"}},[_c('v-row',{staticClass:"mb-8",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"12"}},[_c('ProcessesDetails',{attrs:{"dataProcess":_vm.dataProcess}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"reference__card px-8 mt-8",attrs:{"outlined":"","elevation":"0"}},[_c('v-row',{staticClass:"mb-8",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"12"}},[_c('FeedbackDetails',{attrs:{"dataProcess":_vm.dataProcess,"dataHistorys":_vm.dataHistorys,"show":false,"showInfo":false},on:{"dataFeedback":_vm.dataFeedback}})],1)],1)],1)],1)],1),(_vm.statusID == 12)?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"pa-xl-0 mt-10",staticStyle:{"color":"#fff"},style:({
              'background-color': hover ? '#324CA1' : '#466BE3',
            }),attrs:{"block":"","rounded":""},on:{"click":function($event){return _vm.openModal()}}},[_vm._v(" Rechazar de pruebas ")])]}}],null,false,1462582243)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-btn',{staticClass:"pa-xl-0 mt-10",staticStyle:{"color":"#fff"},style:({
              'background-color': hover ? '#324CA1' : '#466BE3',
            }),attrs:{"block":"","rounded":""},on:{"click":function($event){return _vm.aceptTest()}}},[_vm._v(" Aceptar de pruebas ")])]}}],null,false,4252446637)})],1)],1):_vm._e(),_c('v-dialog',{attrs:{"width":"410","persistent":""},model:{value:(_vm.openConfirm),callback:function ($$v) {_vm.openConfirm=$$v},expression:"openConfirm"}},[_c('v-card',{staticStyle:{"border-radius":"15px"}},[_c('v-card-title',{staticClass:"pt-6"},[_c('v-layout',{attrs:{"justify-center":""}},[_c('img',{staticClass:"ml-6",attrs:{"width":"80","height":"80","src":require("../../assets/icon/alert.svg")}})]),_c('v-btn',{staticStyle:{"display":"flex","left":"18px","bottom":"45px"},attrs:{"color":"#466BE3","icon":"","x-small":""},on:{"click":function($event){_vm.openConfirm = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('p',{staticClass:"document-managmet1__txt-dialog-title"},[_vm._v("¿Está seguro?")]),_c('p',{staticClass:"document-managmet1__txt-dialog-sms pb-0 mb-0"},[_vm._v(" Que desea Denegar las pruebas? ")]),_c('v-textarea',{staticClass:"mt-5",attrs:{"label":"Motivo de No aceptación","outlined":"","rows":"3","dense":"","clearable":""},model:{value:(_vm.denyReason),callback:function ($$v) {_vm.denyReason=$$v},expression:"denyReason"}}),_vm._v(" El motivo de No aceptación debe ser mayor a 20 caracteres. ")],1),_c('v-card-actions',{staticClass:"pb-8"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"block":"","color":"#466BE3","dark":"","rounded":"","outlined":""},on:{"click":function($event){_vm.openConfirm = false}}},[_vm._v(" CERRAR ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{style:({
                  background: _vm.denyReason.length <= 20 ? '#D3D3D3' : '#466BE3',
                }),attrs:{"block":"","color":"#466BE3","dark":"","rounded":"","disabled":_vm.denyReason.length < 20},on:{"click":function($event){return _vm.denyTest()}}},[_vm._v(" No Aceptar ")])],1)],1)],1)],1)],1)],1),_c('Alert',{attrs:{"open":_vm.message.dialog,"text":_vm.message.sms,"title":_vm.message.title,"type":_vm.message.type,"redirect":_vm.message.redirect},on:{"close":function($event){_vm.message.dialog = false}}}),_c('v-overlay',{attrs:{"value":_vm.loadingAll}},[_c('v-progress-circular',{attrs:{"size":120,"color":"#466BE3","indeterminate":""}},[_vm._v(" Cargando... ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }