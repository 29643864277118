<template>
  <div class="pl-8 pr-8">
    <div class="mb-xl-margin-file-sign">
      <v-row class="mt-10">
        <v-col class="text-left" cols="6">
          <h1 class="reference__title">Detalle solicitud general</h1>
        </v-col>
        <v-col class="text-left" cols="6">
          <h1 class="reference__title">Feedback</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card outlined class="reference__card px-8 mt-8" elevation="0">
            <v-row justify="center" class="mb-8">
              <v-col class="px-0" cols="12">
                <ProcessesDetails :dataProcess="dataProcess" />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card outlined class="reference__card px-8 mt-8" elevation="0">
            <v-row justify="center" class="mb-8">
              <v-col class="px-0" cols="12">
                <FeedbackDetails
                  :dataProcess="dataProcess"
                  :dataHistorys="dataHistorys"
                  :show="false"
                  :showInfo="false"
                  @dataFeedback="dataFeedback"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <!-- Status Pruebas -->
      <v-row v-if="statusID == 12">
        <v-col cols="6">
          <v-hover v-slot="{ hover }">
            <v-btn
              @click="openModal()"
              block
              rounded
              style="color: #fff"
              :style="{
                'background-color': hover ? '#324CA1' : '#466BE3',
              }"
              class="pa-xl-0 mt-10"
            >
              Rechazar de pruebas
            </v-btn>
          </v-hover>
        </v-col>
        <v-col cols="6">
          <v-hover v-slot="{ hover }">
            <v-btn
              @click="aceptTest()"
              block
              rounded
              style="color: #fff"
              :style="{
                'background-color': hover ? '#324CA1' : '#466BE3',
              }"
              class="pa-xl-0 mt-10"
            >
              Aceptar de pruebas
            </v-btn>
          </v-hover>
        </v-col>
      </v-row>
      <!-- Modal no aceptar pruebas Status  -->

      <v-dialog v-model="openConfirm" width="410" persistent>
        <v-card style="border-radius: 15px">
          <v-card-title class="pt-6">
            <v-layout justify-center>
              <img
                class="ml-6"
                width="80"
                height="80"
                src="../../assets/icon/alert.svg"
              />
            </v-layout>
            <v-btn
              @click="openConfirm = false"
              color="#466BE3"
              icon
              x-small
              style="display: flex; left: 18px; bottom: 45px"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <p class="document-managmet1__txt-dialog-title">¿Está seguro?</p>
            <p class="document-managmet1__txt-dialog-sms pb-0 mb-0">
              Que desea Denegar las pruebas?
            </p>

            <!-- Agregar cuadro de texto para el motivo -->
            <v-textarea
              v-model="denyReason"
              label="Motivo de No aceptación"
              outlined
              rows="3"
              dense
              clearable
              class="mt-5"
            ></v-textarea>
            El motivo de No aceptación debe ser mayor a 20 caracteres.
          </v-card-text>
          <v-card-actions class="pb-8">
            <v-row>
              <v-col cols="6">
                <v-btn
                  block
                  color="#466BE3"
                  dark
                  rounded
                  @click="openConfirm = false"
                  outlined
                >
                  CERRAR
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn
                  block
                  color="#466BE3"
                  dark
                  rounded
                  @click="denyTest()"
                  :disabled="denyReason.length < 20"
                  :style="{
                    background: denyReason.length <= 20 ? '#D3D3D3' : '#466BE3',
                  }"
                >
                  No Aceptar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </div>
    <Alert
      :open="message.dialog"
      :text="message.sms"
      :title="message.title"
      :type="message.type"
      :redirect="message.redirect"
      @close="message.dialog = false"
    >
    </Alert>
    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        Cargando...
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import color from "@/mixins/color";
import Api from "@/utils/api";
import Alert from "../../components/Alert.vue";
import { VueEditor } from "vue2-editor";
import ProcessesDetails from "../newDevelopment/ProcessesDetails.vue";
import FeedbackDetails from "../newDevelopment/FeedbackDetails.vue";
export default {
  components: {
    Alert,
    VueEditor,
    ProcessesDetails,
    FeedbackDetails,
  },
  mixins: [color],
  data() {
    return {
      denyReason: "",
      loading: {
        table: false,
      },
      modal: {
        info: false,
      },
      message: {
        dialog: false,
        title: "",
        sms: "",
        type: "",
        redirect: "",
      },
      statusID: 0,
      loadingAll: false,
      dataProcess: [],
      dataHistorys: [],
      currentUser: [],
      openConfirm: false,
      dataDeleted: null,
    };
  },
  methods: {
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");

      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
        // console.log(JSON.parse(atob(div[1])));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    dataDewelopment(data) {
      this.create(data);
    },
    create(data) {
      this.loadingAll = true;
      Api.newDevelopment()
        .createDevelopment(this.token, data)
        .then((res) => {
          this.message.dialog = true;
          this.message.sms = res.data.message;
          this.message.title = "Excelente";
          this.message.type = "success";
        })
        .catch((error) => {
          this.message.dialog = true;
          this.message.sms = "Hemos tenido problemas con tu solicitud";
          this.message.title = "¡Oh no!";
          this.message.type = "error";
        })
        .finally(() => {
          this.loadingAll = false;
          this.$root.$emit("procesoFinalizado");
        });
    },
    searchData() {
      this.dataProcess = JSON.parse(
        localStorage.getItem("infoDevelopmentProcess")
      );
    },
    dataFeedback(data) {
      let dataFeedback = {
        workorder_id: this.dataProcess.id,
        mensaje: data.feedbackTxt,
        user_id: this.currentUser.id,
        prioridad_id: this.dataProcess.prioridad_id,
        status_id: this.dataProcess.status_id,
      };
      this.loadingAll = true;
      Api.newDevelopment()
        .createHistory(this.token, dataFeedback)
        .then((res) => {
          this.message.dialog = true;
          this.message.sms = res.data.message;
          this.message.title = "Excelente";
          this.message.type = "success";
        })
        .catch((error) => {
          this.message.dialog = true;
          this.message.sms = "Hemos tenido problemas con tu solicitud";
          this.message.title = "¡Oh no!";
          this.message.type = "error";
        })
        .finally(() => {
          this.loadingAll = false;
          this.dataHistory();
        });
    },
    dataHistory() {
      let data = {
        order_id: this.dataProcess.id,
        user_id: this.currentUser.id,
      };
      Api.newDevelopment()
        .getHistory(this.token, data)
        .then((res) => {
          this.dataHistorys = res.data.data;
          console.log(this.dataHistorys);
        })
        .catch((error) => {})
        .finally(() => {});
    },
    getUser() {
      let currentUser = this.$store.getters.getUser;
      this.currentUser = currentUser;
    },
    getDataURL() {
      this.statusID = this.$route.query.status_id;

      if (this.statusId !== undefined && this.statusId !== null) {
        this.$router.replace({ query: {} });
      }
    },
    aceptTest() {
      console.log(this.dataProcess);
      let dataFeedback = {
        workorder_id: this.dataProcess.id,
        mensaje: "Pruebas aprobadas",
        user_id: this.currentUser.id,
        prioridad_id: this.dataProcess.prioridad_id,
        status_id: "11",
      };
      this.loadingAll = true;
      Api.newDevelopment()
        .createHistory(this.token, dataFeedback)
        .then((res) => {
          this.message.dialog = true;
          this.message.sms = "Solicitud Entregada";
          this.message.title = "Excelente";
          this.message.type = "success";
          this.goList();
        })
        .catch((error) => {
          this.message.dialog = true;
          this.message.sms = "Hemos tenido problemas con tu solicitud";
          this.message.title = "¡Oh no!";
          this.message.type = "error";
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    denyTest() {
      let dataFeedback = {
        workorder_id: this.dataProcess.id,
        mensaje: this.denyReason,
        user_id: this.currentUser.id,
        prioridad_id: this.dataProcess.prioridad_id,
        status_id: "10",
      };
      this.loadingAll = true;
      Api.newDevelopment()
        .createHistory(this.token, dataFeedback)
        .then((res) => {
          this.message.dialog = true;
          this.message.sms = "Solicitud Entregada";
          this.message.title = "Excelente";
          this.message.type = "success";
          this.goList();
        })
        .catch((error) => {
          this.message.dialog = true;
          this.message.sms = "Hemos tenido problemas con tu solicitud";
          this.message.title = "¡Oh no!";
          this.message.type = "error";
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    goList() {
      this.$router.push({
        name: "newDevelopment",
      });
    },
    openModal() {
      this.openConfirm = true;
    },
  },
  created() {
    this.getToken();
    this.searchData();
    this.getDataURL();
    this.getUser();
    this.dataHistory();
  },
  watch: {
    "$store.state.user": {
      handler(newValue, oldValue) {
        this.getUser();
        this.dataHistory();
      },
    },
  },
};
</script>
<style>
@import "./../../assets/css/main.less";

.navidad-text {
  display: flex;
}

.reference__table .v-data-table-header {
  font-family: "RobotoRegular";
  background-color: #f2f4ff;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px;
  color: #353535 I !important;
}

tr,
th,
span .reference__table .text-start .sortable {
  font-size: 16px !important;
}

.reference__card {
  border-radius: 15px !important;
}

.reference__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.references__header {
  color: #353535 !important;
}
</style>
